import { NextPage } from 'next';
import { useState } from 'react';

import { CgClose } from 'react-icons/cg';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { HiMenu } from 'react-icons/hi';
// import { BsCameraVideo } from 'react-icons/bs';

import styled from '@emotion/styled';
import { NextRouter, useRouter } from 'next/router';
import { SHOW_COMMEMORATE } from 'src/constants';
import { IExpandToggle, IMenu } from 'src/interface/layout';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { splitRote } from 'src/utils/helper';

type Props = {
  eventDataLayer: any;
  _data: IMenu[];
};

const NavMobile: NextPage<Props> = ({ eventDataLayer, _data }) => {
  const data: IMenu[] = _data;
  const router: NextRouter = useRouter();
  const [toggle, setToggle] = useState<boolean>(false);
  const [expand, setExpand] = useState<IExpandToggle>({ status: false, key: null });
  function handleToggle(_key: string, _index: number | null): void {
    switch (_key) {
      case 'close':
        setToggle(false);
        setExpand({ key: null, status: false });
        break;
      case 'open':
        setToggle(!toggle);
        break;
      case 'menu':
        setToggle(true);
        setExpand({
          key: _index,
          status: expand?.key === _index ? !expand.status : true
        });
        break;
      default:
        setToggle(false);
        break;
    }
  }

  return (
    <>
      <NavWrapper className='nav-mobile' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
        <div className='btn-menu'>
          {toggle || expand.status ? (
            <CgClose onClick={() => handleToggle('close', null)} />
          ) : (
            <HiMenu
              size={35}
              onClick={() => {
                handleToggle('open', null);
              }}
            />
          )}
        </div>

        <a href={`${splitRote(router)}/category/nationtv/live`}>
          <div className='live-button'>
            <svg x='0px' y='0px' width='17px' height='15.8px' viewBox='0 0 17 15.8'>
              <path d='M8.5,6.8c-0.9,0-1.7,0.8-1.7,1.7c0,0.9,0.8,1.7,1.7,1.7c0.9,0,1.7-0.8,1.7-1.7C10.2,7.6,9.5,6.8,8.5,6.8z'></path>
              <path d='M13.6,8.5c0-2.8-2.2-5.1-5-5.2c-2.8,0-5.1,2.2-5.2,5c0,0,0,0.1,0,0.1c0,1.8,1,3.5,2.6,4.4l0.9-1.4c-1.6-1-2.1-3-1.2-4.6s3-2.1,4.6-1.2c1,0.6,1.7,1.7,1.7,2.9c0,1.2-0.6,2.3-1.7,2.9l0.9,1.4C12.7,12,13.6,10.3,13.6,8.5L13.6,8.5z'></path>
              <path d='M8.5,0C3.8,0,0,3.8,0,8.5c0,3,1.6,5.8,4.3,7.3l0.9-1.4c-3.3-1.9-4.4-6.1-2.5-9.3s6.1-4.4,9.3-2.5c2.1,1.2,3.4,3.5,3.4,5.9c0,2.4-1.3,4.7-3.4,5.9l0.9,1.4c4.1-2.3,5.5-7.5,3.1-11.6C14.4,1.6,11.6,0,8.5,0z'></path>
            </svg>
            <p>ดูเนชั่นทีวี</p>
          </div>
        </a>
        {toggle && (
          <nav className={`nav-dropdown-mobile ${toggle ? 'visible' : 'invisible'}`}>
            <div className='nav-title'>
              <button onClick={() => handleToggle('close', null)} className='close-btn'>
                <CgClose />
              </button>
            </div>
            {/* ---------------------------------- MENU ---------------------------------- */}
            <ul className={'nav-menu'}>
              {/* <li className='active-menu'>
                <div className='d-flex'>
                  <a
                    href={`${splitRote(router)}/`}
                    title='หน้าแรก'
                    aria-label='หน้าแรก'
                    // ! DATALAYER
                    onClick={() =>
                      sendDataLayerGlobal({
                        ...eventDataLayer,
                        data: {
                          title: 'หน้าแรก',
                          heading: 'หน้าแรก',
                          index: 1
                        }
                      })
                    }
                  >
                    <span className='menu'>หน้าแรก</span>
                  </a>
                </div>
              </li> */}
              {(toggle || expand.status) &&
                Array.isArray(data) &&
                data?.length > 0 &&
                data.map((menu, index) => (
                  <>
                    <li key={index} className='active-menu'>
                      <div className='item-menu'>
                        <a
                          href={`${splitRote(router)}/${menu?.link}`}
                          title={menu?.nameTh}
                          aria-label={menu?.nameTh}
                          // ! DATALAYER
                          onClick={() =>
                            sendDataLayerGlobal({
                              ...eventDataLayer,
                              data: {
                                title: menu?.nameTh,
                                heading: menu?.nameTh,
                                index: index + 2
                              }
                            })
                          }
                        >
                          <span className='menu'>{menu?.nameTh}</span>
                        </a>
                        <div className='drop-item' onClick={() => handleToggle('menu', index)}>
                          {/* ------------------------------- Icon Expand ------------------------------ */}
                          {Array.isArray(menu?.sub) && menu?.sub.length > 0 && (expand?.key === index && expand?.status ? <FaMinus /> : <FaPlus />)}
                        </div>
                      </div>
                      {/* --------------------------------- SUBMENU -------------------------------- */}
                      {expand?.key === index && expand?.status && Array.isArray(menu.sub) && (
                        <ul className='nav-sub-menu'>
                          {menu.sub.map((subMenu: any, i: any) => (
                            <li key={i} className='active-sub-menu'>
                              <a
                                href={`${splitRote(router)}${subMenu?.link}`}
                                title={subMenu?.nameTh}
                                aria-label={subMenu?.nameTh}
                                // ! DATALAYER
                                onClick={() =>
                                  sendDataLayerGlobal({
                                    ...eventDataLayer,
                                    data: {
                                      title: subMenu?.nameTh,
                                      heading: `${menu?.nameTh}:${subMenu?.nameTh}`,
                                      index: i + 1
                                    }
                                  })
                                }
                              >
                                <div className='list-sub-menu'>
                                  <span className='sub-menu'>{subMenu?.nameTh}</span>
                                </div>
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  </>
                ))}
              {/* <li className='active-menu'>
                <div className='d-flex'>
                  <a
                    href={`${splitRote(router)}/video`}
                    title='วิดีโอ'
                    aria-label='วิดีโอ'
                    // ! DATALAYER
                    onClick={() =>
                      sendDataLayerGlobal({
                        ...eventDataLayer,
                        data: {
                          title: 'วิดีโอ',
                          heading: 'วิดีโอ'
                        }
                      })
                    }
                  >
                    <span className='menu'>
                      <div className='video-button'>
                        <BsCameraVideo size={20} />
                        <p>วิดีโอ</p>
                      </div>
                    </span>
                  </a>
                </div>
              </li> */}
            </ul>
          </nav>
        )}
      </NavWrapper>
    </>
  );
};

const NavWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: fit-content;

  .menu {
    .live-button {
      background: transparent;
      color: var(--primary-color);
      svg path{
        fill: var(--primary-color);

      }
    }
    .video-button {
      svg {
        color: var(--primary-color-new);
      }
    }
  }
  .video-button{
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 18px;
    svg{
      color: var(--primary-color);
      margin-right: 10px;
    }
  }
  .live-button {
    display: flex;
    align-items: center;
    text-align: center;
    background: #eb212d;
    padding: 4px 8px;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    svg {
      margin-right: 15px;
    }
    svg path {
      fill: #fff;
      /* Copy this @keyframes block to your CSS*/
      @keyframes signalanimate {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      &:nth-of-type(1) {
        -webkit-animation: signalanimate 2s infinite;
        animation: signalanimate 2s infinite;
      }
      &:nth-of-type(2) {
        -webkit-animation: signalanimate 2s infinite;
        animation: signalanimate 2s infinite;
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
      }
      &:nth-of-type(3) {
        -webkit-animation: signalanimate 2s infinite;
        animation: signalanimate 2s infinite;
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
      }
    }
  }

  /* start nav mobile */
  .btn-menu svg {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }
  ul.nav-menu,
  ul.nav-sub-menu {
    list-style: none;
  }
  .invisible {
    visibility: hidden !important;
  }
  nav.nav-dropdown-mobile {
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0);
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    background-color: #fff;
    z-index: 20;
    box-shadow: 1px 0px 10px 0px var(--tertiary-color);
    overflow-y: scroll;

    .nav-title {
      text-align: right;
      svg {
        display: flex;
        align-items: center;
        font-size: 26px;
        color: var(--tertiary-color);
      }
    }
    /* ------------------------------- MOBILE : Navigation Menu -------------------------------- */

    .close-btn {
      background: none;
      top: 10px;
      right: 10px;
      border: none;
      padding: 8px 10px;
      font-size: 16px;
      cursor: pointer;
      svg {
        vertical-align: middle;
        color: var(--primary-color);
        font-size: 30px;
      }
    }

    .img-nav {
      margin: 50px auto 50px auto;
      img {
        width: 100px;
        max-width: 100%;
        height: auto;
      }
    }
    .nav-menu {
      display: flex;
      width: 100%;
      flex-flow: column nowrap;
      padding-inline-start: 0px;
      padding: 0px 15px;
      li {
        margin: 0px;
        border-top: 1px solid var(--primary-color-new);
        span {
          padding: 8px 0px;
          color: var(--primary-color-new);
          display: flex;

          font-size: 18px;
          font-weight: 600;
          width: 100%;
          overflow-x: hidden;
          justify-content: space-between;
          align-items: center;
          text-transform: capitalize;
          transition: all 0.5s ease-out 0s;
          &:hover {
            @media (min-width: 1023px) {
              color: #bbb;
            }
          }
        }
        .nav-sub-menu {
          width: 100%;
          padding-left: 8px;
          .list-sub-menu {
            padding-left: 10px;
            span {
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
        &:nth-child(1) {
          border: none;
          padding-top: 0;
        }
      }
      .active-menu .item-menu a,
      .active-menu .d-flex a {
        font-weight: 600;
      }
      .item-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        span {
          white-space: pre;
        }
        &:hover {
          a {
            width: 50%;
            display: inline-block;
            span {
              @media (min-width: 1023px) {
                color: #bbb;
              }
            }
          }
        }

        .drop-item {
          cursor: pointer;
          display: flex;
          width: 50%;
          justify-content: flex-end;

          svg {
            font-size: 18px;
            color: var(--primary-color-new);
          }
          &:hover {
            svg {
              @media (min-width: 1023px) {
                color: #bbb;
              }
            }
          }
        }
      }
    }
  }
  .logo-img {
    opacity: 0.6;
  }
  /* end nav mobile */
`;

export default NavMobile;
